import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import ToggleClassOnVisible from '../components/ToggleClassOnVisible';

const AboutPage = ({ pageContext }) => {
  const { t } = useTranslation('about');

  const textOverlay = (
    <div className="about__text_overlay_container">
      <Container fullWidth={true}>
        <div className="about__text_overlay_inner_container">
          <div className="about__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>

          <span className="about__text_overlay_title">{t('heroTitle1')} </span>
          <span className="about__text_overlay_title2 text-bold color-green">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  const heroImages = [
    { image: 'img-lead-about-1.jpg', text: '', title: '' },
    { image: 'img-lead-about-2.jpg', text: '', title: '' },
    { image: 'img-lead-about-3.jpg', text: '', title: '' },
    { image: 'img-lead-about-4.jpg', text: '', title: '' },
  ];

  const fadeInDelays = [0, 300, 600];

  return (
    <Layout title="About the Program" className={pageContext.lang}>
      <Hero mediaSource={heroImages} textOverlay={textOverlay} />
      <Section>
        {/* about mission */}

        <Container>
          <Row>
            <Column size={6}>
              <Image filename="img-about-empowering.png" />
            </Column>
            <Column size={6}>
              <h2 className="intro__heading">
                {t('introHeading')}
              </h2>
              <p
                dangerouslySetInnerHTML={{ __html: t('introBody') }}
                className="intro__body"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        {/* reach impact community */}
        <Container>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Image filename="img-about-reach.jpg" />
                <h3 className="color-mid-blue text-bold mt-1.5">
                  {t('cardReachHeading').toUpperCase()}
                </h3>
                <p>{t('cardReachBody')}</p>
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Image filename="img-about-impact.jpg" />
                <h3 className="color-mid-blue text-bold mt-1.5">
                  {t('cardImpactHeading').toUpperCase()}
                </h3>
                <p dangerouslySetInnerHTML={{ __html: t('cardImpactBody') }} />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Image filename="img-about-community.jpg" />
                <h3 className="color-mid-blue text-bold mt-1.5">
                  {t('cardCommunityHeading').toUpperCase()}
                </h3>
                <p>{t('cardCommunityBody')}</p>
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pt-3 pb-3">
        {/* virtual field trip  We launch this Fall 2021*/}
        {/* <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container>
            <Row>
              <Column size={6}>
                <div
                  style={{ backgroundColor: '#d8bdff', paddingTop: '56.25%' }}
                >
                  Image pending approval
                </div>
              </Column>
              <Column size={6}>
                <h2>{t('virtualFieldTripHeading').toUpperCase()}</h2>
                <p className="virtual-field-trip__body">
                  {t('virtualFieldTripBody')}
                </p>
                <div className="h3 text-bold">
                  {t('virtualFieldTripComingSoon')}
                </div>
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
      <Section> */}
        {/* partners */}
        <Container>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="partners">
              <Column medium={6} large={5} className="mb-2">
                <Image
                  filename="abbott-fund-logo.svg"
                  className="partners__logo mb-2"
                  height={50}
                />
                <p className="mb-2">{t('partnersAbbottBody')}</p>
                <p>{t('partnersAbbottBody2')}</p>
              </Column>
              <Column medium={6} large={5} largeOffset={2} className="mb-2">
                <Image
                  filename="de-logo-positive.png"
                  className="partners__logo mb-2"
                  style={{ maxWidth: '215px' }}
                />
                <p dangerouslySetInnerHTML={{ __html: t('partnersDEBody') }} />
              </Column>
            </Row>
          </ToggleClassOnVisible>
        </Container>
      </Section>
      <Section className="pb-0">
        {/* subfooter */}
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container fullBleed fullWidth>
            <Row>
              <Column large={6} className="mb-0">
                <Image filename="img-about-subfooter-statement.jpg" />
              </Column>
              <Column large={6} className="subfooter--statement">
                <div className="h2 color-mid-blue text-bold pl-4 pr-4 pb-2 pt-2">
                  {t('subfooterHeading').toUpperCase()}
                </div>
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
    </Layout>
  );
};

export default AboutPage;
